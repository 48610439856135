.stark-form-header,
.stark-form-builder hr {
  display: none;
}

.policy-list-table #chkAll {
  display: none;
}

.disabled-link {
  pointer-events: none;
}

.forget-input img {
  left: 15px;
  position: absolute;
  top: 16px;
  z-index: 9999;
}

.forget-input .input-group {
  position: relative;
}

.main-sidebar-body .nav li.active a {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

body .pagination {
  float: right;
}

.task-list h4.task-title img.downarrow {
  transform: rotate(0deg);
  transition: all ease-in-out 0.3s;
}

.task-list h4.task-title.collapsed img.downarrow {
  transform: rotate(-90deg);
  transition: all ease-in-out 0.3s;
}

.addtask-section .btn-link {
  text-decoration: none !important;
}

.addtask-section .btn-link:hover {
  text-decoration: underline !important;
}

.progress-input .input-group {
  max-width: 80px;
}

.ml-10 {
  margin-left: 10px;
}

.swal-overlay {
  z-index: 99999 !important;
}

.empty-task {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  min-height: 65vh;
}

.empty-task h4 {
  font-size: 24px;
  line-height: 34px;
  margin: 0 0 15px 0;
  font-weight: 600;
}

.empty-task h5 {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin: 0 0 15px 0;
}

.modal-dialog .form-group .form-label {
  color: #212529 !important;
}

.project-container .row {
  margin-top: 30px;
}

.siderbar-toggle-wrapper {
  max-width: 26px;
  cursor: pointer;
}

.siderbar-toggle-wrapper img {
  max-width: 100%;
}

.main-sidebar {
  transform: translateX(0);
}

.main-sidebar.main-sidebar-sticky {
  transition: all ease-in-out 0.3s;
}

.main-sidebar.hide {
  transform: translateX(-100%);
  transition: all ease-in-out 0.3s;
}

.main-content.side-content.move {
  margin-left: 0;
  transition: all ease-in-out 0.3s;
}

.react-datepicker-popper {
  z-index: 999 !important;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.task-datepicker .react-datepicker {
  position: absolute;
  z-index: 999 !important;
}

.organization-title.text-center h1 {
  font-size: 24px !important;
  margin: 40px 0 25px;
  text-transform: uppercase;
}

.organization .card {
  width: 100% !important;
  max-width: 600px;
}

.estm-block {
  max-width: 250px;
}

.date-link .task-datepicker {
  position: absolute;
}

.box {
  display: block;
  width: 200px;
  height: 100px;
  background-color: #ddd;
}

.pop {
  padding: 0px 0px;
}

.example {
  position: relative;
}

.offcanvas-end {
  width: 50% !important;
}

.modal-end-btn {
  margin-bottom: 15px;
}

.project-details-drawer {
  position: fixed;
  width: 50%;
  right: 0;
  top: 0;
  background-color: #ffffff;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 25px;
  transition: all ease-in-out 0.3s;
  z-index: 999;
}

.drawer-container {
  padding: 20px 0;
  min-height: 100%;
}

.user-permission {
  padding: 0;
  margin: 0;
  /* max-height: 500px;
  overflow-x: auto; */
}

.user-permission li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  transition: all ease-in-out 0.3s;
}

.user-permission li:hover {
  background-color: #f1f1f1;
}

.user-permission li .switch-block {
  margin-left: 25px;
}

.user-permission li .form-switch .form-check-input:checked {
  background-size: auto;
}

.user-permission li .form-check .form-switch {
  margin-bottom: 0;
}

.table-projects .switch-check .form-check-input {
  width: 35px;
  height: 15px;
  background-size: auto;
}

.accordion-button:focus {
  z-index: 1;
  /* border-color: #8ab29c; */
  outline: 0;
  box-shadow: 0 0 0 0 rgb(21 101 57 / 25%);
}

.accordion-body {
  background-color: #fff;
}

.underline {
  text-decoration: underline;
}

.pagination a.page-link {
  width: 33px !important;
  height: 31px !important;
}

.client-main {
  background-image: url(../public/images/login-img.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
}

.client-main .content-area .card {
  max-width: 700px;
  width: 100% !important;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 13px !important;
  border-top-right-radius: 13px !important;
}
.accordion-item:last-of-type .accordion-collapse .accordion-body {
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
}
.accordion-item:last-of-type .accordion-collapse{ 
  border:none !important;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 30px;
  height: 30px;
}

.center-progress {
  position: absolute;
  top: 60%;
  left: 75%;
  margin-top: -50px;
  margin-left: -50px;
  width: 25px;
  height: 25px;
}

.login-page .form-group img.showpass {
  left: auto;
  right: 15px!important;
}
/* 
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: 12px;
  transform: rotate(86deg) !important;
  right: 11px;
  left: auto !important;
  z-index: 9;
} */

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
  left: 52% !important;
  transform: none !important;
}

.swal-wide{
  width:850px !important;
}

.pull-right {
  float: right;
}

.show-rotate {
  transform: rotate(90deg);
}

.section-title{
  font-style: normal !important;
  font-size: 14px !important;
  font-weight: 600;
  color: #24959D;
  /* color: #0086cc; */
}

.referral-card{
  min-height: 91%;
}

.profilepic{
  border-radius: 50%;
  cursor: pointer;
  object-fit: contain;
}

.profile-pen{
  position: absolute;
  top: -3%;
  display: none;
  cursor: pointer;
}

.profile-file:hover~.profile-pen{
  display: block;
  cursor: pointer;
}

.profile-file{
  border-radius: 50%;
  cursor: pointer;
  width: 100px;
  height: 100px;
  position: absolute;
  left: 44%;
  opacity: 0;
  z-index: 999;
}

.profile-file:hover~.profilepic{
  opacity: 0.5;
}

.static-profilepic{
  border-radius: 50%;
  object-fit: contain;
}


.tabs-wrapper li.nav-item {
  margin-right: 25px;
}
.tabs-wrapper li.nav-item:last-child {
  margin-right: 0;
}
.tabs-wrapper li.nav-item .nav-link {
  color: #757575 !important;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  padding: 5px 0;
  border: none;
}
.tabs-wrapper li.nav-item .nav-link.active, .tabs-wrapper li.nav-item .nav-link:hover {
  background-color: transparent;
  color: #393A71 !important;
}
.tabs-wrapper li.nav-item .nav-link.active::after, .tabs-wrapper li.nav-item .nav-link:hover::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #393A71;
}

.active{
  color: #24959D;
  text-decoration: underline;
  cursor: pointer;
}

.file-input-hide {
  height: 0;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
}

.choose-file {
  display: inline-block;
  padding: 8px;
  background: #e9ecef;
  margin-right: 15px;
}

.choosed-file-name-f {
  /* display: none; */
  padding: 8px;
  margin: 0 !important;
  margin-left: -9px !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.border-input-file {
  border: 1px solid #ced4da;
}

.accordion-main.select-role-option .css-b62m3t-container {
  z-index: inherit !important;
}
.select-role-option .css-b62m3t-container .css-1nmdiq5-menu {
  z-index: 9999 !important;
  position: absolute;
  top: 40px;
}
section.content-area.accordion-main .select-role-option {
  z-index: 99999 !important;
  position: relative;
}